import React from 'react'

const Processing = () => {
    return (
      <div className="row">
        <div className="col-8 offset-2 text-center">
          <h2>Processing</h2>
          <p>
            All of our meat is processed at Hudson Meats in Hudson, South Dakota
            – a USDA inspected locker. You will be responsible for arranging pickup with the locker. Please give us a call to place an order! Call
            or email us for details on pricing.
          </p>
        </div>
      </div>
    )
}

export default Processing