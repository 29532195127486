import React from 'react'

import Layout from '../components/layout'
import Welcome from '../components/welcome'
import OurBeef from '../components/ourbeef'
import Processing from '../components/processing'
import Pricing from '../components/pricing'
import Contact from '../components/contact'


const IndexPage = () => {
    return (
        <Layout>
            <div className="container">
                <Welcome />
                <OurBeef />
                <Processing />
                <Pricing />
                <Contact />
            </div>
        </Layout>
    )
}

export default IndexPage