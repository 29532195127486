import React from "react"
import gary from "../images/meetyourfarmer.jpg"

const Welcome = () => {
  return (
    <div>
      <div className="row">
        <div className="col-8 offset-2 text-center">
          <h1>Welcome to Knutson Farm</h1>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-8 col-sm-4 text-center">
          <h2>Meet your farmer</h2>
          <p>
            My name is Gary Knutson and I’m glad you’re visiting. We have a
            passion for quality grass-fed angus beef and would love for you to
            give our beef a try.
          </p>
          <p className="signature">Gary Knutson</p>
        </div>
        <div className="col-8 col-sm-4">
          <img className="img-thumbnail" src={gary} alt="Gary Knutson" />
        </div>
      </div>
    </div>
  )
}

export default Welcome
