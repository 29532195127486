import React from "react"
import WOW from "wowjs";
import "../style/ourbeef.scss"

import feature1 from "../images/feature1.png"
import feature2 from "../images/feature2.png"
import feature3 from "../images/feature3.png"


class OurBeef extends React.Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  render () {
    return (
    <div className="row row_darkGray">
      <div className="col-8 offset-2 text-center">
        <h2>Our Beef</h2>
        <p>
          Our herd is exclusively grass-fed from conception to finish. We take
          extreme pride in the fact we use no GMO’s, no antibiotics and no added
          growth hormones. We believe in giving the animals what they were
          naturally made to consume.
        </p>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-8 col-lg-3 text-center d-flex align-items-stretch pb-2">
          <div className="card wow fadeInLeft">
            <img
              className="card-img-top"
              src={feature1}
              alt="Angus cattle herd"
            />
            <div className="card-body">
              <h5 className="card-title">
                Superior Genetics + Quality Grass and Hay
              </h5>
              <p className="card-text">
                What makes our grass-fed beef so exceptional? Our high quality
                beef is a combination of superior genetics and quality grass and
                hay. We started our herd from one of the most recognized herds
                for quality genetics in the US. And we’ve done extensive
                research to perfect our haying process to support the herd.
              </p>
            </div>
          </div>
        </div>
        <div className="col-8 col-lg-3 text-center d-flex align-items-stretch pb-2">
          <div className="card wow fadeInLeft">
            <img className="card-img-top" src={feature2} alt="Cut of beef" />
            <div className="card-body">
              <h5 className="card-title">Exceptional Characteristics</h5>
              <p className="card-text">
                Grass-fed beef has a reputation for being tougher due to its
                leanness. Our grass-fed beef has very desirable marbling
                qualities. One of the keys to our quality of beef is that we
                have performance genetics that finish and allow for processing
                around 18-20 months of age ensuring tenderness and flavor.
              </p>
            </div>
          </div>
        </div>
        <div className="col-8 col-lg-3 text-center d-flex align-items-stretch pb-2">
          <div className="card wow fadeInLeft">
            <img
              className="card-img-top"
              src={feature3}
              alt="Our farm"
            />
            <div className="card-body">
              <h5 className="card-title">About Us</h5>
              <p className="card-text">
                We’re a third generation farm and have never been more excited
                about our holistic approach to farming. My wife Sandy and I,
                along with our son Kyle, own and operate 120 head of registered
                Black Angus cattle and farm 480 acres of land near Volga, SD.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
}

export default OurBeef
