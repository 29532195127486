import React from "react"
import "../style/contact.scss"

const Contact = () => {
  return (
    <div className="row">
      <div className="col-10 offset-1 col-lg-6 offset-lg-3">
        <h2 className="text-center">Get in touch</h2>
        <p className="text-center">
          Do you value knowing your food from farm to table? That’s cool. We do
          too! We take pride in our operation and would love to give you a tour.
        </p>
        <div id="contact">
          <h2 className="text-center">Contact</h2>
          <address className="text-center pt-2">
            <strong>Knutson Farm</strong><br />
            45915 210th St.<br />
            Volga, SD 57071<br />
            <abbr title="Home Phone">H:</abbr> <a href="tel:605-627-9400">(605) 627-9400</a><br />
            <abbr title="Cell Phone">C:</abbr> <a href="tel:605-690-1685">(605) 690-1685</a><br />
          </address>
        </div>
      </div>
    </div>
  )
}

export default Contact
